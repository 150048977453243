<template>
  <!-- 剧集抽屉 -->
  <a-drawer
    :title="detail.title"
    width="700"
    :closable="false"
    :visible="visible"
    @close="onClose"
    :footer-style="{ textAlign: 'right' }"
  >
    <a-form
      ref="RefForm"
      :model="formState"
      :label-col="{ span: 4 }"
      :wrapper-col="{ span: 16 }"
    >
      <!-- 链接类型 -->
      <a-form-item
        label="链接类型"
        name="link_type"
        :rules="[{ required: true }]"
      >
        <a-radio-group
          v-model:value="formState.link_type"
          @change="onRadioUploadLink"
        >
          <a-radio :value="0">小程序路径</a-radio>
          <a-radio :value="3">微小H5调起链接</a-radio>
          <a-radio :value="1">抖小H5调起链接</a-radio>
        </a-radio-group>
      </a-form-item>
      <!-- 短剧 -->
      <a-form-item
        v-if="formState.link_type !== 0"
        label="小程序"
        name="project_id"
        :rules="[{ required: true, message: '请选择小程序' }]"
      >
        <a-select
          show-search
          v-model:value="formState.project_id"
          placeholder="请选择"
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          :filterOption="filterOption"
          @change="onUploadLink"
        >
          <a-select-option
            v-for="item in projectList"
            :key="`${item.id}-${item.name}`"
            :value="item.id"
          >
            {{ item.name }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <!-- 页面路径 -->
      <a-form-item
        label="页面路径"
      >
        <div v-if="formState.link_type !== 0">
          <div v-if="formState.project_id">
            <a-button size="small" style="margin-top: 6px;" @click="touchCopy">复制</a-button>
            <div style="margin-top: 10px;">
              <span class="link-hint">
                {{ formState.link_url }}
              </span>
            </div>
          </div>
          <span class="link-hint" v-else>请先选择小程序</span>
        </div>
        <div v-else>
          <a-button size="small" style="margin-top: 6px;" @click="touchCopy">复制</a-button>
          <div style="margin-top: 10px;">
            <span class="link-hint">
              {{ formState.link_url }}
            </span>
          </div>
        </div>
      </a-form-item>
    </a-form>
    <!-- 尾部 -->
    <!-- <template #footer>
      <a-button style="margin-right: 8px" @click="onClose">取消</a-button>
      <a-button type="primary" @click="touchSubmit">确定</a-button>
    </template> -->
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { ref, reactive, defineExpose, nextTick } from 'vue'
import { message } from 'ant-design-vue'
import Loading from '@/components/Loading'
import { activityProjectAll, wechatBindAccount } from '@/api/copyright'
import Pub from '@/utils/public'

// emit
const emit = defineEmits(['success'])
// Form实例
const RefForm = ref(null)
// 抽屉展示状态
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 详情
let detail = ref({})
// 项目列表
let projectList = ref([])
// 表单
let formState = reactive({
  // 链接类型
  link_type: 0,
  // 剧集ID
  project_id: undefined,
  // 页面路径
  link_url: undefined
})


// 打开抽屉
function showDrawer (params) {
  // 展开
  detail.value = params
  visible.value = true
  // 赋值
  nextTick(() => {
    // 获取选择项列表
    // getSelectList()
    // 初始化
    onUploadLink()
  })
}

// 获取选择项列表
function getSelectList () {
  activityProjectAll({ platform_id: formState.link_type }).then(res => {
    const { code, data } = res
    if (code === 0) {
      projectList.value = data
    }
  })
}

// 关闭抽屉
function onClose () {
  if (!isLoading.value) {
    // 重置
    RefForm.value.resetFields()
    formState.link_type = 0
    formState.project_id = undefined
    formState.link_url = undefined
    visible.value = false
  }
}

// 切换类型
function onRadioUploadLink () {
  formState.project_id = undefined
  projectList.value = []
  if (formState.link_type !== 0) {
    getSelectList()
  }
  onUploadLink()
}

// 更新链接
function onUploadLink () {
  const params = `ranking_id=${detail.value.id}&source=ranking`
  if (formState.link_type === 0) {
    // 小程序路径
    formState.link_url = `/pages-ranking/index?${params}`
  } else if (formState.link_type === 1) {
    // 抖小H5调起链接
    const landingURL = `${Pub.PROXY_HOST()}/landing.html`
    formState.link_url = `${landingURL}?project_id=${formState.project_id}&platform_id=${formState.link_type}&${params}`
  } else if (formState.link_type === 3) {
    // 微小H5调起链接
    const landingURL = `${Pub.PROXY_HOST()}/landing.html`
    formState.link_url = `${landingURL}?project_id=${formState.project_id}&platform_id=${formState.link_type}&${params}`
  }
}

// 拷贝
function touchCopy () {
  if (formState.link_url) {
    Pub.COPY(formState.link_url)
  }
}

// 模糊匹配
function filterOption (input, option) {
  return `${option.key}`.toLowerCase().includes(`${input || ''}`.toLowerCase())
}

// 暴露出去
defineExpose({
  showDrawer
})

</script>

<style lang="less" scoped>
.a-link {
  margin-left: 8px;
}
.form-item-clear {
  margin-bottom: 0;
}
.form-upload-hint {
  padding-top: 6px;
  color: #8D8D8D;
}
.form-upload-list {
  display: flex;
  flex-wrap: wrap;
  margin-left: -10px;
}
.image-view.form-upload-item {
  margin-top: 10px;
  margin-left: 10px;
  position: relative;
  width: 118px;
  height: 148px;
  border-radius: 4px;
  overflow: hidden;
  img {
    display: inline-block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  &.horizontal {
    width: 148px;
    height: 118px;
  }
}
.upload-item-select {
  position: absolute;
  right: 8px;
  bottom: 8px;
}
.upload-item-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.form-upload-item:hover .upload-item-delete {
  opacity: 1;
}
.date-range {
  color: #8D8D8D;
  padding-left: 100px;
  margin-top: -15px;
}
.link-hint {
  word-wrap: break-word;
  white-space: normal;
  word-break: break-all;
  color: rgba(0, 0, 0, 0.4);
}
</style>