<template>
  <div
    class="image-view"
    :style="`border-radius: ${borderRadius}px;`"
  >
    <!-- 上传失败 -->
    <a-tooltip v-if="isShowErr || isShowErrPro">
      <template #title>{{ errorMsg }}</template>
      <warning-filled class="image-error" />
    </a-tooltip>
    <template v-else>
      <!-- 加载中 -->
      <Loading v-if="isLoading || isLoadingPro" :loading="true"></Loading>
      <!-- 图片展示 -->
      <img v-if="!!url || !!urlPro" class="image-content" :src="isShowPlay ? Pub.VIDEO_COVER({ url: url || urlPro }) : (url || urlPro)" @click="touchImage" />
      <!-- 播放按钮 -->
      <play-circle-outlined v-if="isShowPlay && (!isLoading && !isLoadingPro)" class="image-play" @click="touchPlay"/>
    </template>
    <!-- 移除按钮 -->
    <close-circle-outlined v-if="isShowDelete && (!isLoading && !isLoadingPro)" class="image-delete" @click="touchDelete" />
    <!-- 插槽 -->
    <slot></slot>
  </div>
</template>

<script setup>
import Loading from '@/components/Loading'
import Pub from '@/utils/public'
import { ref, watch } from 'vue'
// 事件
const emit = defineEmits(['delete', 'touch'])
// 参数
const props = defineProps({
  // 图片地址
  url: {
    type: String,
    default: ''
  },
  // 加载
  isLoading: {
    type: Boolean,
    default: false
  },
  // 圆角
  borderRadius: {
    type: Number,
    default: 4
  },
  // 显示错误
  isShowErr: {
    type: Boolean,
    default: false
  },
  // 失败提示
  errorMsg: {
    type: String,
    default: '上传失败'
  },
  // 显示删除按钮
  isShowDelete: {
    type: Boolean,
    default: true
  },
  // 显示播放按钮
  isShowPlay: {
    type: Boolean,
    default: false
  },
  // 上传对象
  fileJson: {
    type: Object,
    default: {}
  }
})

// 参数
let urlPro = ref(null)
let isLoadingPro = ref(0)
let isShowErrPro = ref(0)

// 监听上传对象
watch(props.fileJson, (newVal, oldVal) => {
  if (newVal.status === 'done') {
    // 成功
    isLoadingPro.value = 0
    isShowErrPro.value = 0
    urlPro.value = newVal.url
  } else if (newVal.status === 'error') {
    isLoadingPro.value = 0
    isShowErrPro.value = 1
  } else {
    // isLoadingPro.value = 1
    // 两个图片地址对象都没有值，可以进入加载
    isLoadingPro.value = (!props.url && !urlPro.value)
    isShowErrPro.value = 0
  }
}, { deep: true })

// 点击图片
function touchImage () {
  emit('touch', props.url || urlPro.value)
}

// 点击删除
function touchDelete () {
  emit('delete')
}

// 点击播放
function touchPlay () {
  emit('play')
}
</script>

<style scoped>
.image-view {
  position: relative;
  overflow: hidden;
  background-color: gainsboro;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.image-view .image-content {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-view .image-delete {
  position: absolute;
  right: 8px;
  top: 8px;
  font-size: 18px;
  color: #8D8D8D;
  cursor: pointer;
  opacity: 0;
}
.image-view .image-play {
  position: absolute;
  font-size: 20px;
  color: #FFF;
  cursor: pointer;
}
.image-view:hover .image-delete {
  opacity: 1;
}
.image-view .image-error {
  font-size: 18px;
}
</style>